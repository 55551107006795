import {
  CardMedia,
  CardContent,
  Typography,
  Card,
  CardActionArea,
  Grid,
  Grow,
} from "@mui/material";
import React from "react";
import styled from "styled-components";
import { Layout } from "../components/layout";
import { Social } from "../components/socials/socials";
import { BusinessSkills } from "../data/skills";
import { Loader } from "../styles/common.styles";
import { SocialContainer } from "../styles/web/webExperience.styles";

export const CardContainer = styled.div`
  height: 75vh;
  margin: 5vw;
  overflow: auto;
`;

export const CardImage = styled.div`
  border: 2px solid #eace6b;
  margin: 0;
`;

export const ActionAreaCard = ({ skill, idx }) => {
  return (
    <Grow
      in={true}
      style={{ transformOrigin: "0 0 0" }}
      {...{ timeout: idx * 1000 }}
    >
      <Card sx={{ maxWidth: 345 }}>
        <CardActionArea>
          <CardImage>
            <CardMedia
              component="img"
              image={skill.icon}
              alt="skills"
              height="100%"
              style={{ margin: 0 }}
            />
          </CardImage>
          <CardContent style={{ backgroundColor: "#eace6b" }}>
            <Typography gutterBottom variant="h5" component="div">
              {skill.label}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Lizards are a widespread group of squamate reptiles, with over
              6,000 species, ranging across all continents except Antarctica
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grow>
  );
};

const SkillsPage = () => {
  if (typeof window === "undefined") return null;
  return (
    <Layout bgcolor={"#FFF"}>
      <Loader>
        <CardContainer>
          <Typography
            variant="h5"
            component="div"
            fontWeight={700}
            gutterBottom
          >
            Business tools
          </Typography>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 1, sm: 8, md: 18 }}
          >
            {BusinessSkills.map((skill, index) => {
              return (
                <Grid
                  item
                  xs={1}
                  sm={2}
                  md={4}
                  key={index}
                  style={{ minHeight: "10vw" }}
                >
                  <ActionAreaCard skill={skill} idx={index} />
                </Grid>
              );
            })}
          </Grid>
        </CardContainer>
        <SocialContainer>
          <Social alignCenter={true} />
        </SocialContainer>
      </Loader>
    </Layout>
  );
};

export default SkillsPage;
