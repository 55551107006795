export const BusinessSkills = [
  {
    label: "Jira",
    icon: "https://img.stackshare.io/service/154/default_53605d3093005bd7853e04a258c0d4746e803e29.jpg",
  },
  {
    label: "Slack",
    icon: "https://img.stackshare.io/service/675/RNiSRYOF_400x400.jpg",
  },
  {
    label: "Salesforce",
    icon: "https://img.stackshare.io/service/12695/D78-IV9i_400x400.jpg",
  },
  {
    label: "GitHub",
    icon: "https://img.stackshare.io/service/27/default_869c34d29acc794d60ecdd5d2b5bfc042a80a4ec.jpg",
  },
  {
    label: "AWS IAM",
    icon: "https://img.stackshare.io/service/418/default_e178723a5fe3dcf01c455f7c40a4f002577b132f.png",
  },
];
